<template>
<!-- v-tooltip="
  model === 'requiredCookies' ? 'asdasda'
                            : false
  " -->
  <div class="form-row" :class="model">
    <label :for="model" class="label">
      <input
        :id="model"
        class="input-checkbox"
        type="checkbox"
        name="cookie-permission"
        :checked="checked"
        @change="handleCheckbox(model)"
        :disabled="model === 'requiredCookies'"
      />
      <span class="checkmark"></span>
      <h5 class="title">{{ title }}</h5>
      <p class="text" v-html="text"></p>
    </label>
  </div>
</template>

<script>
export default {
  components: {},
  // props
  props: ['id', 'model', 'title', 'text', 'handleCheckbox', 'checked'],

  // data
  data() {
    return {}
  },

  // computed
  computed: {},

  // mounted
  mounted() {},

  // methods
  methods: {},
}
</script>

<style lang="scss" scoped>
.form-row {
  margin-bottom: 10px;
  position: relative;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  // &:hover {
  //   background-color: rgba(#000, 0.025);
  // }

  .label {
    cursor: pointer;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
      font-weight: bold;
      margin-bottom: 5px;
      padding-right: 10px;
    }
    .text {
      margin: 0;
      padding-right: 10px;
    }
  }
}

/* Hide the browser's default checkbox */
.form-row input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* On mouse-over, add a grey background color */
.form-row input[type="checkbox"] ~ .checkmark {
  background-color: #fff;
  border: 2px solid #000;
}

/* When the checkbox is checked, add a blue background */
.form-row input[type="checkbox"]:checked ~ .checkmark {
  background-color: #fff;
}
.form-row input[type="checkbox"]:checked ~ .checkmark:after {
  border-color: #000;
}
.form-row input[type="checkbox"]:checked:disabled ~ .checkmark {
  opacity: 0.3;
  // background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  opacity: 0;
  transition: opacity 0.15s ease-in;
}


/* Show the checkmark when checked */
.form-row input[type="checkbox"]:checked ~ .checkmark:after {
  opacity: 1;
}

/* Style the checkmark/indicator */
.form-row .checkmark:after {
  width: 8px;
  height: 14px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.form-row:hover input[type="checkbox"]:not(:checked) ~ .checkmark:after {
  opacity: 0.2;
}
</style>
