import Vue from 'vue'

import VueCookies from 'vue-cookies'
// import VTooltip from 'v-tooltip'

import App from './app.vue'

Vue.use(VueCookies)
Vue.$cookies.config('50y')

// Vue.use(VTooltip)

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

const app = new Vue({
  render: (h) => h(App),
}).$mount('#cookie-manager')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
window.__app__ = app
// window.showCookieManager = null // TODO: needed?
// window.hideCookieManager = null // TODO: needed?
