<template>
  <div>
    <modal
      v-show="showModal"
      :show-modal="showModal"
      @close="showModal = false"
    >
      <!-- .body -->
      <div slot="body" class="cookie-manager">
        <!-- .introduction -->
        <div v-if="!isSettingsView" class="introduction">
          <h3 class="title">{{ translations.introduction.title }}</h3>
          <!-- eslint-disable-next-line -->
          <p class="text" v-html="translations.introduction.text"></p>
        </div>
        <!-- /.introduction -->

        <!-- .settings-view -->
        <div v-if="isSettingsView" class="settings-view">
          <h3 class="title">{{ translations.settings.title }}</h3>
          <!-- eslint-disable-next-line -->
          <p class="text" v-html="translations.settings.text"></p>
          <!-- .form-settings -->
          <div class="form-settings">
            <form-row
              model="marketingCookies"
              :checked="marketingCookies"
              :title="translations.settings.permissions.marketingCookies.title"
              :text="translations.settings.permissions.marketingCookies.text"
              :handle-checkbox="handleCheckbox"
            />
            <form-row
              model="trackingCookies"
              :checked="trackingCookies"
              :title="translations.settings.permissions.trackingCookies.title"
              :text="translations.settings.permissions.trackingCookies.text"
              :handle-checkbox="handleCheckbox"
            />
            <form-row
              model="requiredCookies"
              :checked="requiredCookies"
              :title="translations.settings.permissions.requiredCookies.title"
              :text="translations.settings.permissions.requiredCookies.text"
              :handle-checkbox="handleCheckbox"
            />
            <p class="text" v-html="translations.settings.subtitle"></p>
          </div>
          <!-- /.form-settings -->
        </div>
        <!-- /.settings-view -->
      </div>
      <!-- /.body -->

      <!-- .footer -->
      <template slot="footer">
        <button
          v-if="!isSettingsView"
          class="button edit-cookie-settings"
          @click="openSettings"
          >{{ translations.buttons.editSettings }}</button
        >
        <button
          v-if="!isSettingsView"
          class="button accept-cookie-settings"
          @click="handleAccept"
          >{{ translations.buttons.accept }}</button
        >

        <button
          v-if="isSettingsView"
          class="button save-cookie-settings"
          @click="saveSettings"
          >{{ translations.buttons.saveSettings }}</button
        >

        <button
          v-if="isSettingsView"
          class="button delete-cookies"
          @click="deleteCookies"
          >{{ translations.buttons.deleteCookies }}</button
        >
      </template>
      <!-- /.footer -->
    </modal>

    <debug-permissions v-if="cookieManagerConfig.debug" :state="this" :cookie="savedCookie" />
  </div>
</template>

<script>
import Vue from 'vue'
import CookieConsentApi from 'cookie-consent-api'
import {
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'

import Modal from '@components/Modal/index.vue'
import DebugPermissions from '@components/CookieManager/DebugPermissions.vue'
import FormRow from '@components/CookieManager/FormRow.vue'

import { defaultOptions } from '@components/CookieManager/defaultOptions.js'

const options = () => {
  if (window.cookieManagerConfig) {
    return {
      ...defaultOptions,
      ...window.cookieManagerConfig,
    }
  }
  if (window.CookieManagerConfig) {
    // backwards compatibility
    return {
      ...defaultOptions,
      ...window.CookieManagerConfig,
    }
  }
  return defaultOptions
}

if (window) {
  window.cookieManagerConfig = options()
}

const cookieServices = {
  ...window.cookieManagerConfig.cookieServices
}

const targetElement = document.querySelector('.modal-mask')

export default {
  components: {
    Modal,
    DebugPermissions,
    FormRow,
  },

  // props
  props: {},

  // data
  data() {
    return {
      // choices
      requiredCookies: true,
      trackingCookies: false,
      marketingCookies: false,

      cookieConsent: null,
      showModal: false,
      isSettingsView: false,

      cookieNoticeClosed: null,
      consentDate: false,

      savedCookie: {},
      deleteCookiesBeforeSafe: false,
      cookieManagerConfig: {},

      translations: {
        introduction: {
          title: '',
          text: '',
        },
        settings: {
          subtitle: '',
        },
        buttons: {
          editSettings: '',
        },
        messages: {
          editSettings: '',
        },
      },
    }
  },

  // computed
  computed: {},

  // mounted
  mounted() {
    window.CookieManager = this

    this.prepareConfig()
    this.prepareTranslations()

    this.initAnalytics()

    this.applySavedTrackingPermissions()
    this.initCookieConsent()

    if (!this.savedCookie && this.isCookieManagerAllowed()) {
      this.showCookieManager()
    }
  },

  // methods
  methods: {
    deleteCookies: function() {
      // each cookie name
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i]
        const eqPos = cookie.indexOf('=')
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        const paths = [
          '/',
          '/de',
          '/en',
        ]
        // each path
        paths.map(path => {
          // with domain
          this.cookieManagerConfig.cookieDomains.map((domain) => {
            document.cookie = `${name}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=${path}; domain=${domain}; secure=;`
          })
          // without domain
          document.cookie = `${name}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=${path}; secure=;`
        })
      }

      this.cookieManagerConfig.deleteCookiesHelper()

      location.href = '/'
    },
    initAnalytics: function() {
      window.ga('create', this.cookieManagerConfig.analyticsId, {
        storage: 'none', // disable cookies
        anonymizeIp: true,
      })
    },
    isCookieManagerAllowed: function() {
      if (this.excludePage() || this.excludeHost()) return false
      // console.log('isCookieManagerAllowed true')
      return true
    },
    applySavedTrackingPermissions: function() {
      this.savedCookie = Vue.$cookies.get('trackingPermissions') || false

      // console.log('trackingPermissions on mount', this.savedCookie)
      if (this.savedCookie && this.savedCookie.cookieConsent) {
        for (const [key, value] of Object.entries(this.savedCookie)) {
          if (key.includes('Cookies')) {
            // console.log('key, value', key, value)
            this[key] = value
          }
        }
      }
    },
    excludeHost: function() {
      const hostsToExclude = this.cookieManagerConfig.hostsToExclude
      return hostsToExclude.includes(location.host)
    },
    excludePage: function() {
      const pagesToExclude = this.cookieManagerConfig.pagesToExclude
      return pagesToExclude.includes(location.pathname)
    },
    trackAnalyticsEvent: function(event) {
      if (!this.cookieManagerConfig.initialTracking) return false

      const gaEvent = {
        category: event.category || '',
        action: event.action || '',
        label: event.label || '',
        value: event.value || '',
      }

      window.ga(
        'send',
        'event',
        gaEvent.category,
        gaEvent.action,
        gaEvent.label
      )
    },
    disableScroll: function() {
      disableBodyScroll(targetElement)
    },
    enableScroll: function() {
      enableBodyScroll(targetElement)
    },
    showCookieManager: function() {
      this.disableScroll()
      this.showModal = true
    },
    showCookieSettings: function() {
      this.disableScroll()
      this.showModal = true
      this.isSettingsView = true
    },
    hideCookieManager: function() {
      this.enableScroll()
      this.showModal = false
    },
    handleConsentEvents: function() {
      if (!this.cookieManagerConfig.debug) return false

      this.cookieConsent.on('accept', (service) => {
        // console.log('service enabled: ', service)
      })
      this.cookieConsent.on('refuse', (service) => {
        // console.log('service refused: ', service)
      })
    },
    handleAccept: function() {
      // marketing as default choice
      this.setCookiePermission('trackingCookies', true)
      this.setCookiePermission('marketingCookies', true)

      this.saveSettings()

      this.trackAnalyticsEvent({
        category: 'Cookie Manager',
        action: 'accept - clicked',
      })
    },
    handleConsentChange: function(permission, permissionValue) {
      // console.log('permissionValue', permissionValue)

      const savedCookie = this.getSavedCookie() || false
      const {
        trackingCookies,
        marketingCookies,
      } = savedCookie

      const marketingChange = permission === 'marketingCookies' && marketingCookies && !permissionValue
      const trackingChange = permission === 'trackingCookies' && trackingCookies && !permissionValue

      if (marketingChange) console.log('marketingChange to more restricted level')
      if (trackingChange) console.log('trackingChange to more restricted level')

      // start with false
      this.deleteCookiesBeforeSafe = false
      console.log('set deleteCookiesBeforeSafe', false)

      // check saved cookie
      if (marketingChange && !permissionValue) {
        this.deleteCookiesBeforeSafe = true
        console.log('1. set deleteCookiesBeforeSafe', true)
      }
      if (trackingChange && !permissionValue) {
        this.deleteCookiesBeforeSafe = true
        console.log('2. set deleteCookiesBeforeSafe', true)
      }

      // check current state
      if ((!this.marketingCookies||!this.trackingCookies) && !permissionValue) {
        this.deleteCookiesBeforeSafe = true
        console.log('3. set deleteCookiesBeforeSafe', true)
      }
      if ((this.marketingCookies||this.trackingCookies) && (!marketingChange && !trackingChange) && permissionValue) {
        this.deleteCookiesBeforeSafe = false
        console.log('4. set deleteCookiesBeforeSafe', false)
      }

      // found only requiredCookies permission in saved cookie
      if (!marketingCookies && !trackingCookies && (
        permission === 'marketingCookies'||permission === 'trackingCookies'
      )) {
        this.deleteCookiesBeforeSafe = false
        console.log('5. set deleteCookiesBeforeSafe', false)
      }

    },
    handleCheckbox: function(permission) {
      const permissionValue = !this[permission]
      this.handleConsentChange(permission, permissionValue)
      this.toggleCookiePermission(permission)

      this.trackAnalyticsEvent({
        category: 'Cookie Manager',
        action: permission + ' - toggled',
      })
    },
    saveSettings: function() {
      if (this.deleteCookiesBeforeSafe) {
        this.deleteCookies()
      }
      this.consentDate = new Date()
      this.saveCookie()
      this.disableAllServices()
      this.hideCookieManager()
      setTimeout(this.applyTrackingPermissions, 200)
      setTimeout(() => {
        this.isSettingsView = false
      }, 1000)
    },
    applyTrackingPermissions: function() {
      const servicesToEnable = []

      if (this.requiredCookies) {
        servicesToEnable.push(cookieServices.requiredCookies)
      }
      if (this.marketingCookies) {
        servicesToEnable.push(cookieServices.marketingCookies)
      }
      if (this.trackingCookies) {
        servicesToEnable.push(cookieServices.trackingCookies)
      }

      // apply selected permissions
      servicesToEnable.forEach((services) => {
        services.map((service) => this.cookieConsent.accept(service))
      })
    },
    saveCookie: function() {
      // set cookie
      const trackingPermissions = {
        requiredCookies: this.requiredCookies,
        trackingCookies: this.trackingCookies,
        marketingCookies: this.marketingCookies,

        cookieConsent: this.cookieConsent,
        consentDate: this.consentDate,
      }
      Vue.$cookies.set('trackingPermissions', trackingPermissions)
    },
    openSettings: function() {
      this.isSettingsView = true

      this.trackAnalyticsEvent({
        category: 'Cookie Manager',
        action: 'open settings - clicked',
      })
    },
    disableAllServices: function() {
      const servicesToDisable = Object.keys(
        this.cookieManagerConfig.cookieServices
      ).map((value) => value)

      servicesToDisable.forEach((services) => {
        cookieServices[services].forEach((service) => {
          this.cookieConsent.refuse(service)
        })
      })
    },
    toggleCookiePermission: function(permission) {
      this[permission] = !this[permission]
    },
    setCookiePermission: function(permission, value) {
      this[permission] = value
    },
    getSavedCookie: function() {
      let trackingPermissions = {}
      try {
        trackingPermissions = Vue.$cookies.get('trackingPermissions') || {}
      } catch (e) {}
      return trackingPermissions
    },
    initCookieConsent: function() {
      const services = [
        ...this.cookieManagerConfig.cookieServices.marketingCookies,
        ...this.cookieManagerConfig.cookieServices.trackingCookies,
        ...this.cookieManagerConfig.cookieServices.requiredCookies,
      ]

      this.cookieConsent = new CookieConsentApi({
        cookieName: 'cookie_consent_settings',
        cookieDuration: 365 * 50,
        services,
      })
      this.handleConsentEvents()
    },
    prepareConfig: function() {
      this.cookieManagerConfig = {
        ...defaultOptions,
        ...window.cookieManagerConfig,
      }
      window.cookieManagerConfig = this.cookieManagerConfig
    },
    prepareTranslations: function() {
      const translations = this.cookieManagerConfig.translations
      const defaultTranslations = defaultOptions.translations

      this.translations = {
        introduction: {
          ...defaultTranslations.introduction,
          ...translations.introduction,
        },
        settings: {
          ...defaultTranslations.settings,
          ...translations.settings,
        },
        buttons: {
          ...defaultTranslations.buttons,
          ...translations.buttons,
        },
      }
    },
  },
}
</script>

<style lang="scss">
@import 'index.scss';
</style>
