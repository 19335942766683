export const defaultOptions = {
  debug: true,

  // analytics
  initialTracking: true,
  analyticsId: 'UA-XXXX',

  // cookie
  cookieServices: {
    requiredCookies: [],
    trackingCookies: ['googleTagManager'],
    marketingCookies: ['hotjar', 'youtube'],
  },
  cookieDomains: [

    // local
    '127.0.0.1',
    'localhost',

    // elbphilharmonie
    '.elbphilharmonie.de',
    'www.elbphilharmonie.de',
    '.staging.elbphilharmonie.de',

    // konzerthaus
    '.konzerthaus.de',
    'www.konzerthaus.de',
    'staging.konzerthaus.de',
    '.staging.konzerthaus.de',

    // koelner-philharmonie
    '.koelner-philharmonie.de',
    'www.koelner-philharmonie.de',
    'staging.koelner-philharmonie.de',
    '.staging.koelner-philharmonie.de',

    // mir
    '.mir.de',
    'www.mir.de',

    // wiener
    '.wienerphilharmoniker.at',
    'www.wienerphilharmoniker.at',
    '.wphdev.at',
    'www.wphdev.at',
    'live.wienerphilharmoniker.at',

  ],
  pagesToExclude: [
    // '/de/impressum',
    '/en/datenschutz',
    '/de/datenschutz',
  ],
  hostsToExclude: [
    // 'www.wphdev.at',
    // '127.0.0.1:8000',
  ],

  // translations
  translations: {
    introduction: {
      title: `Wir verwenden Cookies`,
      text: `Mit der Auswahl “Akzeptiere Tracking” erlaubst Du adidas die Verwendung von Cookies, Pixeln, Tags und ähnlichen Technologien. Wir nutzen diese Technologien, um Deine Geräte- und Browsereinstellungen zu erfahren, damit wir Deine Aktivität <a href="/de/datenschutz">nachvollziehen können</a>.`,
    },
    settings: {
      title: `Tracking-Einstellungen bearbeiten`,
      text: `Bitte wähle und akzeptiere Deine Tracking-Einstellungen.`,
      subtitle:
        'Alle Details zu unseren Cookies findest Du in unserer <a href="/de/datenschutz" title="Datenschutzerklärung" data-no-swup>Datenschutzerklärung</a>.',
      permissions: {
        requiredCookies: {
          title: `Nur erforderliche Tracking-Einstellungen`,
          text: `Wir nutzen Cookies, um die Bedienung der Website zu ermöglichen und sicherzustellen, dass bestimmte Funktionen ordnungsgemäß funktionieren, wie die Möglichkeit, sich anzumelden oder ein Produkt in den Warenkorb zu legen. Dieses Tracking ist immer aktiviert, da Du sonst die Website nicht sehen oder online einkaufen kannst.`,
        },
        trackingCookies: {
          title: `trackingCookies`,
          text: `text für trackingCookies`,
        },
        marketingCookies: {
          title: `Analyse zur Optimierung der Webseite`,
          text: `Wir nutzen funktionales Tracking, um zu analysieren, wie unsere Webseite genutzt wird. Diese Daten helfen uns, Fehler zu entdecken und neue Designs zu entwickeln. Es erlaubt uns ebenso die Effektivität unserer Website zu testen. Zudem liefern diese Cookies Erkenntnisse für Werbeanalysen und Affiliate-Marketing.`,
        },
      },
    },
    buttons: {
      accept: `akzeptieren`,
      deleteCookies: `Cookies löschen`,
      editSettings: `Einstellungen bearbeiten`,
      saveSettings: `Einstellungen speichern`,
    },
    // messages: {
    //   cookiesDeleted: `Cookies wurden gelöscht`,
    // },
  },
  deleteCookiesHelper: function() {
    console.log('inside deleteCookiesHelper')
  },
}
