<template>
   <transition name="slide-fade">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              default footer
            </slot>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',

  // props
  props: {},

  // data
  data() {
    return {}
  },

  // computed
  computed: {},

}
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
