<template>
  <div>
    <div class="tracking-permission-state debug-stats">
      <span>tracking permission state (not saved)</span>
      requiredCookies: {{ state.requiredCookies }}<br />
      trackingCookies: {{ state.trackingCookies }}<br />
      marketingCookies: {{ state.marketingCookies }}<br />
      consentDate: {{ state.consentDate }}<br />
    </div>

    <div
      v-if="cookie.requiredCookies"
      class="tracking-permission-cookie debug-stats"
    >
      <span>tracking permission cookie (saved)</span>
      requiredCookies: {{ cookie.requiredCookies }}<br />
      trackingCookies: {{ cookie.trackingCookies }}<br />
      marketingCookies: {{ cookie.marketingCookies }}<br />
      consentDate: {{ cookie.consentDate }}<br />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  // props
  props: ['state', 'cookie'],

  // data
  data() {
    return {}
  },

  // computed
  computed: {},

  // mounted
  mounted() {},

  // methods
  methods: {},
}
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
